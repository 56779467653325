import { Injectable } from '@angular/core';
var OwlDateTimeIntl = (function () {
    function OwlDateTimeIntl() {
        this.upSecondLabel = 'Add a second';
        this.downSecondLabel = 'Minus a second';
        this.upMinuteLabel = 'Add a minute';
        this.downMinuteLabel = 'Minus a minute';
        this.upHourLabel = 'Add a hour';
        this.downHourLabel = 'Minus a hour';
        this.prevMonthLabel = 'Previous month';
        this.nextMonthLabel = 'Next month';
        this.prevYearLabel = 'Previous year';
        this.nextYearLabel = 'Next year';
        this.prevMultiYearLabel = 'Previous 21 years';
        this.nextMultiYearLabel = 'Next 21 years';
        this.switchToMonthViewLabel = 'Change to month view';
        this.switchToMultiYearViewLabel = 'Choose month and year';
        this.cancelBtnLabel = 'Cancel';
        this.setBtnLabel = 'Set';
        this.rangeFromLabel = 'From';
        this.rangeToLabel = 'To';
        this.hour12AMLabel = 'AM';
        this.hour12PMLabel = 'PM';
    }
    OwlDateTimeIntl.decorators = [
        { type: Injectable },
    ];
    OwlDateTimeIntl.ctorParameters = function () { return []; };
    return OwlDateTimeIntl;
}());
export { OwlDateTimeIntl };
